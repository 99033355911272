<template>
  <v-container class="pa-5" fluid>
    <v-row no-gutters>
      <v-col cols="auto">
        <MachineModelImage :size="85"
          :relativePath="machine && machine.MachineModel && machine.MachineModel.ModelImage">
        </MachineModelImage>
      </v-col>
      <v-col class="pl-3 d-flex flex-column justify-space-between">
        <h5 style="font-size: 14px;">{{ machine.DisplayName }}</h5>
        <p class="mb-0" style="font-size: 12px; margin-top: 8px;">
          {{ machine.Supplier && machine.Supplier.Name }}
        </p>
        <b class="mb-0" style="font-size: 12px;">
          {{ machine.MachineModel && machine.MachineModel.Name }}
        </b>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col align="center">
        <v-dialog v-model="dialog" width="500" :disabled="!qrUrl">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <TwoLineBtn :title="$t('text.qr_code')" :icon="'mdi-qrcode'" :icon-color="!qrUrl ? 'grey' : 'tertiary'">
              </TwoLineBtn>
            </div>
          </template>
          <v-card v-if="qrUrl">
            <v-card-title class="primary darken-1 white--text py-2">
              <h5>{{ $t('text.qr_code') }}</h5>
            </v-card-title>
            <h5 style="font-size: 13px;" class="px-6 mt-3">{{ machine.DisplayName }}</h5>
            <VueQRCodeComponent :text="qrUrl" class="ml-6 my-6"></VueQRCodeComponent>
            <a :href="qrUrl" class="mx-6">{{ $t('text.link') }}</a>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn plain color="primary" text @click="dialog = false">
                {{ $t('text.close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col align="center">
        <TwoLineBtn :title="$t('text.agreement')"
          :icon="machine.ServiceAgreement ? 'mdi-check-circle' : 'mdi-close-circle'"
          :icon-color="machine.ServiceAgreement ? 'green' : 'red'">
        </TwoLineBtn>
      </v-col>
      <!--
      <v-col align="center">
        <TwoLineBtn :title="'Agreement'" :icon="machine.ServiceAgreement ? 'mdi-check-circle' : 'mdi-close-circle'"
          :icon-color="machine.ServiceAgreement ? 'green' : 'red'">
        </TwoLineBtn>
      </v-col>
      -->
    </v-row>
    <v-row no-gutters class="mt-3">
      <v-col :cols="isPhoneBool ? 7 : 12">
        <v-text-field :label="$t('text.serial_number')" :value="machine.SerialNumber"
          class="borderless-input text-input-blue" hide-details readonly>
        </v-text-field>
      </v-col>
      <v-col :cols="isPhoneBool ? 5 : 12">
        <v-text-field :label="$t('text.qr_code')" :value="machine.Qrcode" class="borderless-input text-input-blue"
          hide-details readonly>
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import http from "@/api/client";
import noimage from "@/assets/noimage.jpg";
//import ImitationInput from "@/components/Inputs/ImitationInput.vue";
import MachineModelImage from "@/components/Cards/MachineModelImage.vue";
import TwoLineBtn from "@/views/public/TwoLineBtn.vue";
import VueQRCodeComponent from 'vue-qrcode-component';

export default {
  name: "PublicMachineDetails",
  props: {
    machine: Object,
  },
  components: {
    MachineModelImage,
    TwoLineBtn,
    VueQRCodeComponent,
  },

  data() {
    return {
      dialog: false,
      qrUrl: null,
    };
  },

  mounted() { },

  methods: {
    imageLoadError(item /*, url*/) {
      item.ModelImage = noimage;
    },
    getQrUrl() {
      http()
        .get("api2/qr/Link/" + this.machine.Id)
        .then((result) => {
          console.log('qrUrl', result.data.qr);
          this.qrUrl = result.data.qr
        }).finally(() => {
          this.loading = false;
        });
    }
  },

  computed: {},

  watch: {
    'machine.Id': function () {
      this.getQrUrl();
    }
  },
};
</script>


<style lang='scss'>
.text-input-blue .v-text-field__slot input {
  color: var(--v-primary-base) !important;
  font-size: 14px !important;
}
</style>