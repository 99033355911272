<template>
  <v-timeline-item class="pb-2" small :color="date ? (passedColor ? passedColor : 'primary') : 'grey'">
    <div :class="date ? (passedColor ? passedColor +'--text' : '') : 'grey--text'">
      <b>{{ text }}</b><br>
      {{ date ? new Date(date).toLocaleDateString() : 'TBA' }}
    </div>
  </v-timeline-item>
</template>


<script>
export default {
  name: 'TimeLineItem',
  props: {
    date: String,
    text: String,
    passedColor: String,
  },
  components: {},

  data() {
    return {};
  },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped></style>