<template>
  <div>
    <h6 class="mb-4 ml-5">{{$t('text.important_dates')}}</h6>
    <v-timeline class="pt-2" dense>
      <TimeLineItem v-for="item in sortedList" :key="item.text" :text="item.text" :date="item.date"
        :passedColor="item.passedColor">
      </TimeLineItem>
    </v-timeline>
  </div>
</template>


<script>
import TimeLineItem from "@/views/public/TimeLineItem.vue";

export default {
  name: 'timeline',
  props: {
    machine: Object,
  },
  components: {
    TimeLineItem,
  },

  data() {
    return {};
  },

  methods: {
    sortByDate(array) {
      if (!array || !array.length) {
        return array;
      }
      var comp = (a, b) => this.compareByDate(a.date, b.date)
      return array.sort(function (a, b) {
        if (a.date === null) {
          return -1;
        }
        if (b.date === null) {
          return 1;
        }
        return comp(a, b);
      });
    },

    compareByDate(a, b) {
      return new Date(b) - new Date(a);
    },
  },

  computed: {
    sortedList() {
      var list = [
        {
          text: this.$t('text.depricated'),
          date: this.machine?.RetiredDate,
          passedColor: 'error',
        },
        {
          text: this.$t('text.warrenty_expiry'),
          date: this.machine?.WarrantyExpiresDate,
          passedColor: 'warning',
        },
        {
          text: this.$t('text.installed'),
          date: this.machine?.InstallationDate,
        },
        {
          text: this.$t('text.bought'),
          date: this.machine?.BoughtDate,
        },
      ];
      return this.sortByDate(list);
    }
  },

  watch: {},
};
</script>


<style lang='scss' scoped></style>