<template>
    <div style="position: relative;">
        <p class="mb-1" :style="!expanded && isPhoneBool ? 'max-height: 20px; overflow-y: hidden;' : ''">
            <slot></slot>
        </p>
        <v-row v-if="isPhoneBool" no-gutters justify="center"
            style="position: absolute; bottom: 0; padding-top: 10px; width: 100%;"
            :style="expanded ? '' : 'background-image: linear-gradient(transparent, white);'"
            @click="expanded = !expanded">
            <v-btn icon smal class="mb-n1">
                <v-icon size="30">{{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
        </v-row>
    </div>
</template>


<script>
export default {
    name: 'MobileCollapseSection',
    props: {},
    components: {},

    data() {
        return {
            expanded: false,
        };
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>