<template>
  <div style="width: 100vw;">
    <v-card color="primary" dark class="fixed_header">
      <v-list-item>
        <v-list-item-icon class="mr-3 my-auto">
          <v-card :to="parentRoute" flat color="transparent">
            <v-icon size="30">
              mdi-chevron-left
            </v-icon>
          </v-card>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $route.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <div style="height: calc(100vh - 48px); overflow: auto;">
      <router-view v-bind="routerViewProps" />
    </div>
  </div>
</template>


<script>

export default {
  name: "AppBarRouterView",
  props: {
    routerViewProps: Object,
  },
  components: {

  },

  data() {
    return {

    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    getNamedParents(routes, matched) {
      let parent = null
      const parents = []
      const parentPath = []
      for (const record of matched) {
        let path = record.path
        if (!path) {
          path = '/'
        }
        if (parent) {
          parentPath.push(parent.path)
        }
        if (parentPath.length) {
          path = path.substr(parentPath.join('/').length + 1)
        }
        let next = routes.find(r => r.path === path)
        if (next.path) {
          parent = next
          routes = next.children
          if (!next.name && next.children) {
            next = next.children.find(r => !r.path)
          }
          parents.push(next)
        } else {
          break
        }
      }
      return parents.slice(0, parents.length - 1)
    }
  },

  computed: {
    parentRoute() {
      const parents = this.getNamedParents(this.$router.options.routes, this.$route.matched)
      if (parents.length) {
        return { name: parents[parents.length - 1].name, query: this.$route.query }
      }
      else {
        return { name: 'home' }
      }
    },
  },

  watch: {},
};
</script>


<style lang='scss'>
.fixed_header {
  border-radius: 0px !important;
  width: 100vw;
}
</style>