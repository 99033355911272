<template>
  <v-container fluid class="pa-0">
    <v-alert type="info" icon="mdi-shield-lock-outline" class="mb-0 py-2 inset_bottom_shaddow"
      style="font-size: 13px !important;" prominent text>
      <template v-slot:prepend>
        <div v-if="isPhoneBool"></div>
      </template>
      <h4 class="mb-1">
        <v-icon v-if="isPhoneBool" color="info" size="20" class="mb-1 mr-1">mdi-shield-lock-outline</v-icon>
        {{ $t('text.public_mode') }}
      </h4>
      <p class="mb-1" :style="!expanded && isPhoneBool ? 'max-height: 15px; overflow-y: hidden;' : ''">{{
          $t('text.public_mode_body') }}
      </p>
      <v-row v-if="isPhoneBool" no-gutters justify="center"
        style="position: absolute; bottom: 35px; padding-top: 10px; width: 90%;"
        :style="expanded ? '' : 'background-image: linear-gradient(transparent, #e6f1f7);'"
        @click="expanded = !expanded">
        <v-btn icon smal class="mb-n3">
          <v-icon color="primary" size="30">{{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-row>
      <v-row no-gutters justify="space-between" class="pr-4">
        <v-card v-if="isPadBool" flat color="#fbfbfb" class="mt-1 mb-n4 px-1 pt-1">
          <LanguageSelector />
        </v-card>
        <v-btn v-if="!$vuetify.breakpoint.mdAndUp" color="primary" small class="mt-2" @click="showDialog = !showDialog">
          {{ $t('text.login') }}
        </v-btn>
      </v-row>
    </v-alert>
    <v-card v-if="showDialog" style="height: 100vh; width: 100vw; position: absolute; top: 0; z-index: 100;"
      class="d-flex align-center justify-center">
      <v-btn style="position: absolute; right: 16px; top: 16px" icon @click="showDialog = !showDialog">
        <v-icon size="40" color="error">mdi-close</v-icon>
      </v-btn>
      <slot name="loginform"></slot>
    </v-card>
  </v-container>
</template>


<script>
import LanguageSelector from "@/components/Inputs/LanguageSelector.vue";

export default {
  name: "PublicQRView",
  props: {},
  components: {
    LanguageSelector,
  },

  data() {
    return {
      expanded: false,
      showDialog: false,
    };
  },

  mounted() {
    this.setFavicon("faviconPublicPortal.ico"); //method from main.js
  },

  methods: {
    switchToPrivatePortal() { },
  },

  computed: {

  },

  watch: {},
};
</script>

<style type="scss">
.inset_bottom_shaddow {
  -moz-box-shadow: inset 0 -10px 10px -10px #b5b5b5;
  -webkit-box-shadow: inset 0 -10px 10px -10px #b5b5b5;
  box-shadow: inset 0 -10px 10px -10px #b5b5b5;
}
</style>