<template>
    <v-card class="d-flex flex-column align-center justify-center pa-2" flat link color="transparent" width="fit-content">
        <v-icon :color="iconColor" style="opacity: 0.7">{{ icon }}</v-icon>
        <p class="mb-0 mt-1">{{ title }}</p>
    </v-card>
</template>


<script>
export default {
    name: 'TwoLineBtn',
    props: {
        icon: String,
        iconColor: String,
        title: String,
    },
    components: {},

    data() {
        return {};
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>