<template>
  <div class="px-3 py-2" style="width: 100vw;">
    <v-card link @click="$emit('click')" :to="to">
      <v-list-item dense class="px-3">
        <v-list-item-icon class="mr-3 my-auto">
          <v-icon>{{ icon ?? 'mdi-alert' }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ title ?? 'Nav List Item' }}</v-list-item-title>
          <v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="ml-2 my-auto">
          <v-card dark color="primary" class="rounded-pill px-2">
            <small>12</small>
          </v-card>
        </v-list-item-action>
        <v-list-item-action class="ml-2 my-auto">
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>
      <slot name=""></slot>
    </v-card>
  </div>
</template>


<script>

export default {
  name: "NavListItem",
  props: {
    icon: String,
    title: String,
    subtitle: String,
    to: Object,
  },
  components: {

  },

  data() {
    return {

    };
  },

  mounted() {

  },

  methods: {

  },

  computed: {

  },

  watch: {},
};
</script>


<style lang='scss'></style>